import Link from 'next/link'
import React from 'react'
// @ts-ignore
import RawFormattedMessage from '../components/RawFormattedMessage'
import styles from './Footer.module.css'
import { useT } from '../helpers/useT'

const currentTime = new Date()
const year = currentTime.getFullYear()

type FooterProps = {
  locale: string
}

export const Footer = ({ locale }: FooterProps) => {
  const t = useT()

  return (
    <footer>
      <div className={styles.root}>
        <p className={styles.copyright}>©{year} Blacklane GmbH</p>
        <div className={styles.linksWrapper}>
          <a href='mailto:partner@blacklane.com' className={styles.links}>
            <RawFormattedMessage id='footer.contact-us' />
          </a>
          <Link href={`https://www.blacklane.com/${locale}/imprint`}>
            <a className={styles.links}>
              <RawFormattedMessage id='footer.legal-notice' />
            </a>
          </Link>
          <Link href={t('chauffeur-privacy-policy.link')}>
            <a className={styles.links}>
              <RawFormattedMessage id='footer.privacy-policy' />
            </a>
          </Link>
        </div>
      </div>
    </footer>
  )
}
